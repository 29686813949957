<style>
.PlanejamentoAcaoFormCampos__Card--treslinhas {
  min-height: 268px;
  height: 100%;
}
.PlanejamentoAcaoFormCampos .title-float {
  font-weight: 400;
  font-size: 20px;
}
.Painel_Planejamento_Acao .v-expansion-panel__header__icon{
  margin-right: 1em;
}
.Painel_Planejamento_Acao .v-expansion-panel__header{
  padding:0;
  margin-left: 1.5em;
  min-height: 42px;
}
</style>
<template>
<div class="PlanejamentoAcaoFormCampos">
  <v-form ref="formGeral"
          v-model="formGeralValido"
          lazy-validation
          autocomplete="off">
    <v-card>
      <v-card-title class="pb-0">
        <div class="card-title" slot="titulo-acao">
          {{ $t('label.criacao_acao')}} :: {{ tituloTipoAcao }}
        </div>
      </v-card-title>
      <v-subheader slot="subtitulo-acao">
        {{subtituloTipoAcao}}
      </v-subheader>

      <v-container fluid grid-list-md class="pt-0 pl-3">
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="descricao"
              v-model="acao.descricao"
              :rules="[rules.required]"
              :disabled="somenteLeitura || configuracao.indDescricaoAutomatica"
              :label="`${$tc('label.descricao_planejamento_acao', 1)} *`"/>
          </v-col>
          <v-col cols="12"
            v-if="acaoEdicao.fluxo && acaoEdicao.fluxo.passoAtual.observacao">
            <v-text-field
              v-model="acaoEdicao.fluxo.passoAtual.observacao"
              :label="`${$tc('label.justificativa', 1)}`"
              :disabled="true"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div class="my-2 title-float accent--text">
      {{$tc('label.configuracao_planejamento_acao', 1)}}
    </div>

    <v-container fluid grid-list-md class="my-2">
      <v-row>
        <v-col cols="12" md="6" class="pa-0 pr-md-2">
          <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
            <v-container fluid grid-list-md>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    autocomplete="off"
                    id="planejamento-autocomplete-divisao"
                    class="custom-autocomplete"
                    v-model="acao.divisao"
                    return-object
                    :rules="rules.requiredFunction('divisao', 'nome')"
                    :items="divisoesFiltradas"
                    :label="`${$tc('label.divisao', 1)} *`"
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    item-text="nome"
                    item-value="id"
                    required
                    :disabled="somenteLeitura || desabilitaDivisao"
                    :clearable="true"
                    @input="divisaoAlterada"
                    @click:append="() => triggerSelecao('planejamento-autocomplete-divisao')"
                    @click.native="(i) => buscaAutocomplete(i, buscarDivisoes)">
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-show="!esconderEntidade(acao.unidadeNegocio)"
                  cols="12" class="py-0">
                  <v-autocomplete
                    autocomplete="off"
                    id="planejamento-autocomplete-unidadenegocio"
                    class="custom-autocomplete"
                    v-model="acao.unidadeNegocio"
                    return-object
                    :items="unidadesNegocioDivisao"
                    :rules="rules.requiredFunction('unidadeNegocio', 'nomExtensao')"
                    :label="`${$tc('label.unidade_negocio', 1)} *`"
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    item-text="nomExtensao"
                    item-value="id"
                    required
                    :disabled="somenteLeitura || desabilitaUnidadeNegocio"
                    :clearable="true"
                    @input="extensaoAlterada"
                    @click:append="() => triggerSelecao('planejamento-autocomplete-unidadenegocio')"
                    @click.native="(i) => buscaAutocomplete(i, buscarUnidadesNegocioDivisao)">
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="configuracao.temExtensaoCarteira"
                  v-for="(ec, index) in configuracao.extensoesCarteira" :key="index"
                  cols="12" class="py-0">
                  <v-autocomplete
                    autocomplete="off"
                    :id="`planejamento-autocomplete-carteira-${ec.label}`"
                    class="custom-autocomplete"
                    v-model="acao[ec.label]"
                    return-object
                    :items="listasExtensoesCarteira[ec.label]"
                    :rules="rules.requiredFunction(ec.label, 'nomExtensao')"
                    :label="`${ ec.descricao } *`"
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    item-text="descricao"
                    item-value="id"
                    required
                    :disabled="somenteLeitura
                     || desabilitaExtensaoCarteira
                      || (listasExtensoesCarteira[ec.label]
                       && listasExtensoesCarteira[ec.label].length === 1)"
                    :clearable="true"
                    @input="extensaoAlterada">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-menu
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  :disabled="somenteLeitura">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :value="formatarData(acao.dtaInicio)"
                        :required="campoObrigatorio"
                        :disabled="somenteLeitura"
                        clearable
                        @click:clear="limpaData"
                        :label="`${$t('label.data_inicio')} *`"
                        prepend-icon="event"
                        :readonly="true">
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      color="primary"
                      v-model="acao.dtaInicio"
                      :is-vigencia-trimestral="isAcaoVigenciaTrimestral"
                      :disabled="somenteLeitura"
                      :required="campoObrigatorio"
                      :min="bloqueioPorPerfil()"
                      @input="emitirCampoProdutoAlterado()">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <metadados-campo-dinamico-data
                    :metadados="definicaoDtaFim"
                    v-model="acao.dtaFim"
                    :somente-leitura="somenteLeitura"
                    :objeto-container="acao"
                    :tipo-selecao="isAcaoVigenciaTrimestral ? 'month' : 'date'"
                    :is-vigencia-trimestral="isAcaoVigenciaTrimestral"
                    :is-data-fim="true"
                    @input="lembreteConfirmarPeriodo();emitirCampoProdutoAlterado()"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-2">
          <planejamento-acao-form-origem-acao
            ref="formOrigemAcao"
            v-if="indModoVarejo"
            :acao="acao"
            :nova-acao="novaAcao"
            :somente-leitura="somenteLeitura"
            :tipo-acao="tipoAcao"
            :configuracao="configuracao"
          />
          <planejamento-acao-form-foco-acao
            ref="formFocoAcao"
            v-else
            :acao="acao"
            :nova-acao="novaAcao"
            :tipo-acao="tipoAcao"
            :somente-leitura="somenteLeitura"
            :configuracao="configuracao"
            @PlanejamentoAcaoFormFocoAcao__alteradoFoco="alteradoFoco"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-card v-if="isDetalheAcaoTemplate">
      <v-card-title>
        <h2 class="my-2 title-float">{{ $tc('title.detalhe_acao_planejamento_acao', 1)}}</h2>
      </v-card-title>
      <planejamento-acao-form-detalhes-acao
        ref="detalheAcao"
        v-if="isDetalheAcaoTemplate"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
    </v-card>

    <div class="my-2 title-float accent--text">
      {{ $tc('title.campo_geral_planejamento_acao', 2)}}
    </div>
    <v-card>
      <v-container fluid grid-list-md>
        <metadados-container-layout
          v-if="parametrosAplicados"
          :metadados-entidade="metadadosParametrizado"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="acao"
          :campos-formulario="camposFormulario"
          :ignora-obrigatorios="false"
          :ordenacao-campos="ordenacaoFormulario"
          :explode-hierarquia="true"
          :somente-leitura="somenteLeitura"
          @MetadadosContainerLayout_valorAlterado="valorDinamicoAlterado"
          ref="container">
        </metadados-container-layout>
        <v-row :layout-class="layoutClass" v-if="exibeJustificativaCancelamento">
          <v-col cols="12">
            <v-alert
              text
              dense
              color="grey"
              border="left"
            >
              {{ $t('message.acao_cancelada_justificativa', infoCancelamento)}}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div v-if="visualizaSecaoRoi">
      <div class="my-2 title-float accent--text">
        {{ $tc('title.roi', 2)}}
      </div>
      <v-card>
        <v-container fluid grid-list-md>
          <planejamento-acao-form-roi
            ref="formRoi"
            v-if="visualizaSecaoRoi"
            :acao="acao"
            :nova-acao="novaAcao"
            :somente-leitura="somenteLeitura"
            :configuracao="configuracao"
          />
        </v-container>
      </v-card>
    </div>

    <div class="mt-3 mb-2 title-float accent--text" v-if="visualizaSecaoUploadAnexo">
      {{$tc('label.anexo', 2)}}
    </div>
    <v-card v-if="visualizaSecaoUploadAnexo">
      <v-container fluid grid-list-md>
        <planejamento-acao-form-anexo-arquivo
          ref="formAnexoArquivo"
          @FormAnexoArquivo__qtde="atualizaQtdeArquivosAnexados"
          :acao="acaoEdicao"
          :configuracao="configuracao"
          :nova-acao="novaAcao"/>
      </v-container>
    </v-card>
  </v-form>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import PlanejamentoAcaoFormFocoAcao from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormFocoAcao';
import PlanejamentoAcaoFormRoi from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormRoi';
import MetadadosCampoDinamicoData from '../../../shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoFormCamposDefinicao from './PlanejamentoAcaoFormCamposDefinicao';
import PlanejamentoAcaoFormOrigemAcao from './PlanejamentoAcaoFormOrigemAcao';
import PlanejamentoAcaoFormAnexoArquivo from './PlanejamentoAcaoFormAnexoArquivo';
import PlanejamentoAcaoFormDetalhesAcao from './PlanejamentoAcaoFormDetalhesAcao';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';

export default {
  components: {
    MetadadosCampoDinamicoData,
    MetadadosContainerLayout,
    PlanejamentoAcaoFormFocoAcao,
    PlanejamentoAcaoFormOrigemAcao,
    PlanejamentoAcaoFormRoi,
    PlanejamentoAcaoFormAnexoArquivo,
    PlanejamentoAcaoFormDetalhesAcao,
  },
  mixins: [
    PlanejamentoAcaoFormCamposDefinicao,
  ],
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    tipoAcao: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    qtdeArquivosAnexados: 0,
    formGeralValido: false,
  }),
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    tituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.nome : '';
    },
    subtituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.descricao : '';
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    isEdicao() {
      return !this.novaAcao;
    },
    exibeJustificativaCancelamento() {
      const { passoGeral } = this.acaoEdicao;
      if (!passoGeral || passoGeral.status !== 'CANCELADO') return false;
      return passoGeral.cancelouAprovada;
    },
    infoCancelamento() {
      const { passoGeral } = this.acaoEdicao;
      const {
        justificativaCancelamento,
        perfilCancelamento,
        usuarioCancelamento,
      } = passoGeral;

      const responsavelCancelamento = this.usuarioLogado.tipo !== 'FUNCIONARIO'
        ? perfilCancelamento : usuarioCancelamento;

      return {
        justificativaCancelamento,
        responsavelCancelamento,
      };
    },
    visualizaSecaoRoi() {
      return this.configuracao.indHabilitarCalculoRoi && (!this.usuarioCliente
        || (this.usuarioCliente && this.configuracao.indPermitirClienteVisualizarRoi));
    },
    visualizaSecaoUploadAnexo() {
      if (!this.configuracao.configuracao.passo1.statusPermitidosUploadPlanejamento) {
        return false;
      }
      return Object.values(this.configuracao.configuracao.passo1.statusPermitidosUploadPlanejamento)
        .some((val) => val);
    },
    isDetalheAcaoTemplate() {
      return this.configuracao.configuracao.passo3.template === 'DETALHE_ACAO';
    },
    isDetalheCalculoHistorico() {
      const { configuracao } = this.configuracao;
      return configuracao.passo3.template === 'CALCULO_HISTORICO';
    },
    isAcaoVigenciaTrimestral() {
      return this.configuracao.indVigenciaTrimestral;
    },
  },
  methods: {
    lembreteConfirmarPeriodo() {
      alert(this.$t('message.lembrete_confirmacao_periodo'));
    },
    onEvent(evento) {
      if (evento.ehEvento('alterarValorAcao')) {
        this.valorAcaoAlteradoOutroPasso(evento);
      }
    },
    emitirCampoProdutoAlterado() {
      window.console.log('emitirCampoProdutoAlterado');
      const evento = new AcaoComponenteEvento('campoProdutoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    emitirFocoAlterado() {
      const evento = new AcaoComponenteEvento('focoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    getObjetoPasso() {
      return this.getObjetoNatural();
    },
    preencherObjetoFormatado(acao) {
      acao.passoGeral = this.getObjetoFormatado();
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      if (!this.validacaoCustomizada(isSolicitandoAprovacao)) {
        return false;
      }
      if (this.isDetalheAcaoTemplate) {
        if (!this.validaSecaoDetalheAcao()) {
          return false;
        }
      }
      if (this.visualizaSecaoRoi) {
        const isAcaoSalvar = true;
        if (!this.validaSecaoRoi(isAcaoSalvar)) {
          return false;
        }
      }
      return true;
    },
    valida() {
      const resultadoFormGeral = this.$refs.formGeral.validate();

      if (!resultadoFormGeral) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }
      if (this.visualizaSecaoRoi
          && this.isDetalheAcaoTemplate) {
        const isAcaoSalvar = false;
        return resultadoFormGeral
            && this.validaSecaoDetalheAcao()
            && this.validaSecaoRoi(isAcaoSalvar)
            && this.validarDatas();
      }
      if (this.visualizaSecaoRoi) {
        const isAcaoSalvar = false;
        return resultadoFormGeral
            && this.validaSecaoRoi(isAcaoSalvar)
            && this.validarDatas();
      }
      if (this.isDetalheAcaoTemplate) {
        return resultadoFormGeral
            && this.validaSecaoDetalheAcao()
            && this.validarDatas();
      }

      return resultadoFormGeral
          && this.validarDatas();
    },
    filtrarCamposFormulario() {
      if (this.configuracao.valorAcaoCalculadoAutomaticamente && !this.isDetalheAcaoTemplate) {
        this.camposFormulario.padrao = this.camposFormulario.padrao.filter((c) => c.labelCampo !== 'valor');
      }
    },
    alteraValorAcao(val) {
      this.acao.valor = val;
      this.$emit('PlanejamentoAcaoForm__CampoValorAlterado');
    },
    esconderEntidade(entidade) {
      if (entidade && entidade.desAtributos) {
        const json = JSON.parse(entidade.desAtributos.value) || {};
        return !!(json && json.esconderEntidade);
      }
      return false;
    },
    extensaoAlterada() {
      this.emitirCampoProdutoAlterado();
      if (this.$refs.formFocoAcao) {
        this.$refs.formFocoAcao.limpaFocoAcao();
      }
    },
    valorDinamicoAlterado(alteracao) {
      const { mapaEntidades } = this.getProdutoMetadado;
      const dependenciasProduto = Object.keys(mapaEntidades);

      const { label } = alteracao;
      if (label === 'produto' || dependenciasProduto.indexOf(label) >= 0) {
        this.emitirCampoProdutoAlterado();
      }

      if (label === 'valor') {
        const evento = new AcaoComponenteEvento('campoValorAlterado');
        this.$emit('PlanejamentoAcaoForm__evento', evento);
      }
    },
    valorAcaoAlteradoOutroPasso(evento) {
      this.acao.valor = evento.objeto.valor;
      this.acao.valorCalculado = evento.objeto.valorCalculado;

      this.$emit('PlanejamentoAcaoForm__evento', new AcaoComponenteEvento('campoValorAlterado'));
    },
    getObjetoNatural() {
      const objeto = { ...this.acao, ...this.getFocoOuOrigem() };
      if (this.isDetalheAcaoTemplate) {
        objeto.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
      return objeto;
    },
    getFocoOuOrigem() {
      const objeto = {};

      if (this.indModoVarejo) {
        const { fornecedor, grupoFornecedor, fornecedorPagador } = this.acao;
        if (this.$refs.formOrigemAcao.exibeOrigemGrupoFornecedor) {
          objeto.grupoFornecedor = grupoFornecedor;
        } else {
          objeto.fornecedor = fornecedor;
        }
        if (this.$refs.formOrigemAcao.exibeFornecedorPagador) {
          objeto.fornecedorPagador = fornecedorPagador;
        }
      } else {
        const {
          cliente,
          holding,
          regional,
          extensaoCliente,
          clientePagador,
          fornecedorPagador,
        } = this.acao;
        if (holding && holding.id) {
          objeto.holding = holding;
        }
        if (cliente && cliente.id) {
          objeto.cliente = cliente;
        }
        if (regional && regional.id) {
          objeto.regional = regional;
        }
        if (clientePagador && clientePagador.id) {
          objeto.clientePagador = clientePagador;
        }
        if (extensaoCliente && extensaoCliente.id) {
          objeto.extensaoCliente = extensaoCliente;
        }
        if (fornecedorPagador && fornecedorPagador.id) {
          objeto.fornecedorPagador = fornecedorPagador;
        }
        objeto.focoAcao = this.$refs.formFocoAcao.getTextoFoco();
      }
      return objeto;
    },
    getObjetoFormatado() {
      const { idConfiguracao } = this.configuracao;
      const {
        id,
        descricao,
        divisao,
        unidadeNegocio,
        valorCalculado,
        valor,
      } = this.acao;

      const objetoFormatado = {
        id,
        idAcaoTipo: this.tipoAcao.id,
        idConfiguracao,
        descricao,
        idDivisao: divisao.id,
        idUnidadeNegocio: unidadeNegocio.id,
        valorCalculado,
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      if (this.configuracao.valorAcaoCalculadoAutomaticamente && this.isDetalheCalculoHistorico) {
        objetoFormatado.valor = valor;
      }

      this.formataVigenciaAberta(objetoFormatado);
      this.formataExtensoes(objetoFormatado);
      this.formataCamposDinamicos(objetoFormatado);

      if (this.indModoVarejo) {
        this.formataOrigemAcao(objetoFormatado);
      } else {
        this.formataFocoAcao(objetoFormatado);
      }
      if (this.visualizaSecaoRoi) {
        objetoFormatado.dadosRoi = this.$refs.formRoi.getDadosRoi();
      }
      if (this.isDetalheAcaoTemplate) {
        objetoFormatado.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
      return objetoFormatado;
    },
    formataVigenciaAberta(objetoFormatado) {
      if (this.isAcaoVigenciaTrimestral) {
        objetoFormatado.dtaInicio = moment(this.acao.dtaInicio, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        objetoFormatado.dtaFim = moment(this.acao.dtaFim, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else {
        objetoFormatado.dtaInicio = this.acao.dtaInicio;
        objetoFormatado.dtaFim = this.acao.dtaFim;
      }
    },
    formataExtensoes(objetoFormatado) {
      objetoFormatado.mapaExtensoes = {
        ...this.$refs.container.getValoresDependencias(),
      };
      this.configuracao.extensoesCarteira.forEach((e) => {
        objetoFormatado.mapaExtensoes[`id_${e.label}`] = this.acao[e.label].id;
      });
    },
    formataCamposDinamicos(objetoFormatado) {
      const camposDinamicos = this.$refs.container.getValoresCamposDinamicos();
      if (Object.keys(camposDinamicos).length !== 0) {
        objetoFormatado.mapaCamposDinamicos = { ...camposDinamicos };
      }
    },
    formataFocoAcao(objetoFormatado) {
      const {
        cliente,
        holding,
        regional,
        extensaoCliente,
        clientePagador,
        fornecedorPagador,
      } = this.$refs.formFocoAcao.getAcao();

      if (holding && holding.id) {
        objetoFormatado.idHolding = holding.id;
      }
      if (cliente && cliente.id) {
        objetoFormatado.idCliente = cliente.id;
      }
      if (regional && regional.id) {
        objetoFormatado.idRegional = regional.id;
      }
      if (clientePagador && clientePagador.id) {
        objetoFormatado.idClientePagador = clientePagador.id;
      }
      if (extensaoCliente && extensaoCliente.id) {
        objetoFormatado.idExtensaoCliente = extensaoCliente.id;
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    formataOrigemAcao(objetoFormatado) {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.acao;
      if (this.$refs.formOrigemAcao.exibeOrigemGrupoFornecedor) {
        objetoFormatado.idGrupoFornecedor = grupoFornecedor.id;
      } else {
        objetoFormatado.idFornecedor = fornecedor.id;
      }
      if (this.$refs.formOrigemAcao.exibeFornecedorPagador) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    validaSecaoRoi(isAcaoSalvar = false) {
      return this.$refs.formRoi.validaSecaoRoi(isAcaoSalvar);
    },
    validaSecaoDetalheAcao() {
      return this.$refs.detalheAcao.validaSecaoDetalheAcao();
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
      ];
      configuracoes.forEach((cfg) => {
        const campos = this.camposFormulario.padrao;
        const campo = campos.filter((c) => c.labelCampo === cfg.entidade)[0];
        campo.metadadosCampo = cfg.metadadosCampo;
      });
    },
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracao.aplicaParametrizacao(this.getAcaoMetadado);
      this.configuracao
        .parametrizaCamposOpcionais(this.camposFormulario.padrao);

      if (this.isDetalheAcaoTemplate) {
        const campoValor = this.camposFormulario.padrao.filter((c) => c.labelCampo === 'valor')[0];
        campoValor.desAtributos = { desabilitado: true };
      }
    },
    preencherExtensoesCarteiras() {
      this.configuracao.extensoesCarteira.forEach((e) => {
        const extensao = this.acao[e.label];
        extensao.descricao = `${extensao.idExterno} - ${extensao.nomExtensao}`;
        this.listasExtensoesCarteira[e.label] = [extensao];
      });
    },
    montaObjetoAcao() {
      Object.assign(this.acao,
        {
          ...this.acaoEdicao.passoGeral,
          ...this.acaoEdicao.passoGeral.mapaExtensoes,
          ...this.acaoEdicao.passoGeral.mapaCamposDinamicos,
        });
      this.preencherExtensoesCarteiras();
      const { status } = this.acao;
      this.acao.status = this.$tc(`status_entidade.${status.toLowerCase()}`, 1);

      if (this.indModoVarejo) {
        setTimeout(this.$refs.formOrigemAcao.setAcaoEdicao);
      } else {
        setTimeout(this.$refs.formFocoAcao.setAcaoEdicao);
      }

      if (this.visualizaSecaoRoi) {
        setTimeout(this.$refs.formRoi.setAcaoEdicao);
      }

      this.unidadesNegocioDivisao = [this.acao.unidadeNegocio];
      this.divisoesFiltradas = [this.acao.divisao];
    },
    validacaoCustomizada(isSolicitandoAprovacao) {
      if (!isSolicitandoAprovacao) {
        return true;
      }
      if (this.configuracao.uploadObrigatorioArquivo && this.qtdeArquivosAnexados === 0) {
        this.$toast(this.$t('message.upload_arquivo_obrigatorio_solicitacao'));
        return false;
      }
      return true;
    },
    validarDatas() {
      const dataInicial = moment(this.acao.dtaInicio);
      const dataFinal = moment(this.acao.dtaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    preencherValoresAutomaticos() {
      if (this.configuracao.indDescricaoAutomatica) {
        this.acao.descricao = this.configuracao.descricaoAutomatica;
      }

      if (this.configuracao.indVigenciaAutomatica) {
        this.acao.dtaInicio = this.moment().format('YYYY-MM-DD');
        this.acao.dtaFim = this.acao.dtaInicio;
      }
    },
    alteradoFoco(isFocoRegional) {
      if (this.visualizaSecaoRoi) {
        this.$refs.formRoi.setFocoRegional(isFocoRegional);
        if (this.acao.divisao && (this.acao.cliente || this.acao.holding)) {
          this.$refs.formRoi.focoAlterado();
        } else {
          this.$refs.formRoi.setValoresZerados();
        }
      }
      this.emitirFocoAlterado();
      this.emitirCampoProdutoAlterado();
    },
    atualizaQtdeArquivosAnexados(qtde) {
      this.qtdeArquivosAnexados = qtde;
      this.$emit('PlanejamentoAcaoForm__AtualizaIndTemEvidencia', qtde > 0);
    },
    limpaData() {
      this.valorInterno = null;
      this.emiteValorAlterado(null);
      setTimeout(() => this.alteraValorApresentavel());
    },
    bloqueioPorPerfil() {
      if (this.usuarioLogado.idPerfil !== 29) {
        const hoje = new Date();
        const dataLimite = new Date(hoje);
        dataLimite.setDate(hoje.getDate() - 120);
        const primeiroDiaMes = new Date(dataLimite.getFullYear(), dataLimite.getMonth(), 1);
        return primeiroDiaMes.toISOString().split('T')[0];
      }
      return null;
    },
    formatarData(data) {
      return data ? moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY') : '';
    },
    atualizarData(valor) {
      this.acao.dtaInicio = valor
        ? moment(valor, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : null;
    },
  },
  mounted() {
    if (!this.somenteLeitura && this.novaAcao) {
      this.buscarDivisoesUsuario();
    }
    this.filtrarCamposFormulario();
    this.aplicaParametrizacoes();

    if (this.isEdicao) {
      this.montaObjetoAcao();
    } else {
      this.preencherValoresAutomaticos();
    }

    this.configuraMetadadoCruzados();

    this.parametrosAplicados = true;
    window.scrollTo(0, 0);
  },
};
</script>
